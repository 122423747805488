.projects {
  background: #fff;
  padding: 90px 20px;
  text-align: center;
}

.projects h2 {
  color: #333;
  animation: fadeIn 1s ease-in-out;
  text-align: center;
  margin-bottom: 40px;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.project {
  background: #ecf0f1;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
  animation: fadeInUp 1s ease-in-out;
  width: 220px;
}

.Projectim {
  width: 100%;
  height: auto;
  box-sizing: border-box;
}
.Projecti {
  width: 100%;
  height: 50%;
box-sizing: border-box;
 
}
h2{
  margin-right: 10px;
}

.project:hover {
  transform: translateY(-10px);
}

.project h3 {
  color: #3498db;
  margin-bottom: 10px;
}

.project p {
  color: #666;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
