.App {
  font-family: Arial, sans-serif;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: #333;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-brand {
  font-size: 24px;
  font-weight: bold;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.navbar-links a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.navbar-links a:hover {
  color: #f39c12;
}

section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease-in-out;
}

.header {
  background: linear-gradient(to right, #8e44ad, #3498db);
  padding: 100px 20px;
  color: #fff;
  text-align: center;
}

.header h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.header p {
  font-size: 24px;
}

.about {
  background: #ecf0f1;
  padding: 60px 20px;
  text-align: center;
}

.about h2 {
  color: #333;
  margin-bottom: 20px;
}

.about p {
  color: #666;
  max-width: 600px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 1.6;
}

.projects {
  background: #fff;
  padding: 60px 20px;
  text-align: center;
}

.projects h2 {
  color: #333;
  margin-bottom: 40px;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.project {
  background: #ecf0f1;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  text-align: left;
}

.project h3 {
  color: #3498db;
  margin-bottom: 10px;
}

.project p {
  color: #666;
}

.contact {
  background: #ecf0f1;
  padding: 60px 20px;
  text-align: center;
}

.contact h2 {
  color: #333;
  margin-bottom: 20px;
}

.contact form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.contact label {
  font-size: 18px;
  color: #333;
}

.contact input,
.contact textarea {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.contact button {
  padding: 10px 20px;
  background: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.contact button:hover {
  background: #2980b9;
}
